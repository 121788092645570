import React, { Component } from 'react';

class CustomNumber extends Component {
    constructor() {
        super();
        this.state = {
            currentValue: 0
        }

        this.eventsHandler = this.eventsHandler.bind(this);
    }

    eventsHandler = (event) => {
        event.target.previousValue = this.state.currentValue;

        this.setState({
            currentValue: event.target.value
        })
        this.props.change(event);
    }

    preventNumberInput = (event) => {
        let check = parseInt(event.key);

        if (event.key.toLowerCase() === "e") {
            event.preventDefault();
        } else if (event.target.value.length === 1 && !isNaN(check)) {
            event.preventDefault();
        } else if (check > this.props.max && !isNaN(check)) {
            event.preventDefault();
        }
    }

    render() {
        return (
            <input id={this.props.id} type="number" onKeyDown={(event) => { this.preventNumberInput(event) }} max={this.props.max} min={this.props.min} placeholder={this.state.currentValue}
                incremental={this.props.incremental} onChange={(event) => this.eventsHandler(event)} className="form-control" />
        )
    }
}

export default CustomNumber;