import React, { Component } from 'react';
import Currency from './currency';

class CustomRange extends Component {
    state = {
        labelValue: 0
    }

    componentDidMount() {
        this.setState({
            labelValue: this.props.max
        })
    }

    labelValueHandler = (event) => {
        this.setState({ labelValue: event.target.value });
        this.props.onChange(event);
    }

    render() {
        return (
            <div className="justify-content-center" >
                <input type="range" id={this.props.id} min={this.props.min} max={this.props.max} value={this.props.value}
                    className="custom-range w-100" onChange={(event) => this.labelValueHandler(event)} />
                <div className="d-flex justify-content-between">
                    <span className="float-left"><Currency currency={this.props.currency}>{this.props.min}</Currency></span>
                    <span className="grey-text"> <Currency currency={this.state.currency}>{this.state.labelValue}</Currency> </span>
                    <span className="float-right"><Currency currency={this.props.currency}>{this.props.max}</Currency></span>
                </div>
            </div>
        );
    }
}

export default CustomRange;