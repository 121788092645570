import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { settingsReducer } from './settingsReducer';
import { countryReducer } from './countryReducer';
import { generalReducer } from './generalReducer';
import { costCalcFormReducer } from './costCalcFormReducer';
import { profileReducer } from './profileReducer';

const reducers = combineReducers({
    settings: settingsReducer,
    countries: countryReducer,
    general: generalReducer,
    costCalculator: costCalcFormReducer,
    profile: profileReducer
})

export default function initializeStore(state = {}) {
    return createStore(
        reducers,
        state,
        composeWithDevTools(applyMiddleware(thunkMiddleware))
    )
}