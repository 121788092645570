import React from 'react';
import { MDBView, MDBMask } from 'mdbreact';
import ContentCard from './content-card';
import { Markup } from 'interweave';

const countryDescription = (props) => {

    const image = props.countryName ?
        <MDBView className="cbi-programme-image">
            <img src={process.env.PUBLIC_URL + `/images/countries/${props.countryName}/image/image.jpg`} className="img-fluid" alt="" />
            <MDBMask className="flex-center" overlay="blue-grey-strong">
                <h2 className="white-text">{props.countryName}</h2>
            </MDBMask>
        </MDBView> : null;

    const description = props.countryDesc ?
        <div className="p-5 cbi-programme-description">
            <Markup content={props.countryDesc}> </Markup>
        </div> : null;

    const gif = props.countryName ?
        <div className="cbi-programme-gif text-center">
            <img src={process.env.PUBLIC_URL + `/images/countries/${props.countryName}/gif/gif.gif`} title={props.countryName} alt={props.countryName} />
        </div> : null;

    return (
        <ContentCard marginClass="ml-auto" bodyClass="m-0 p-0">
            {image}
            {description}
            {gif}
        </ContentCard>
    )
}

export default countryDescription;