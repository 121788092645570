import * as actions from '../actions/actions';

const data = {
    profile: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        residence: "",
        legal: false,
    }
};

export const profileReducer = (state = data, action) => {
    let data = null;
    let newstate = null;

    switch (action.type) {
        case actions.SET_PROFILE:
            return Object.assign({}, state, {
                profile: action.payload
            })
        case actions.CLEAR_PROFILE:
            return Object.assign({}, state, {
                profile: {
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    residence: "",
                    legal: false,
                }
            })
        case actions.SET_PROFILE_PROP:
            newstate = {
                ...state.profile,
                ...action.payload
            };

            return Object.assign({}, state, {
                profile: newstate
            })
        default:
            return state;
    }
}