import React from 'react';
import { MDBIcon } from 'mdbreact';
import ContentCard from './content-card';
import Currency from '../shared/currency';

const calculatedPrice = (props) => {
    const price = props.price ? props.price : 0;

    return (
        <ContentCard marginClass="ml-auto" bodyClass="m-0 p-0">
            <div className="cbi-programme-price">
                <MDBIcon icon="euro-sign" className="cbi-programme-price-currency" />
                <h1 className="cbi-programme-price-value"><Currency>{price}</Currency></h1>
            </div>
        </ContentCard>
    )
}
export default calculatedPrice;