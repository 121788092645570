import * as actions from '../actions/actions';

const data = {
    chosenCountry: null,
    chosenInvestmentPackage: "",
    countryPermutations: "",
    calculatedProgramme: {
        price: 0,
        type: null,
        dependents: 0,
        spouse: false,
    },
    ProgrammeDetails: {}
}

export const countryReducer = (state = data, action) => {
    let programme = null;
    let newstate = null;
    let data = null;

    switch (action.type) {
        case actions.GET_COUNTRIES:
            return Object.assign({}, state, {
                countries: action.payload
            })
        case actions.GET_COUNTRY:
            return Object.assign({}, state, {
                chosenCountry: action.payload.country,
                chosenInvestmentPackage: action.payload.investmentPackage
            })
        case actions.PROGRAMME_DETAILS: {
            data = { ...action.payload };
            newstate = { ...state.ProgrammeDetails, ...data };

            return Object.assign({}, state, {
                ProgrammeDetails: newstate
            })
        }
        case actions.GET_PERMUTATIONS:
            return Object.assign({}, state, {
                countryPermutations: action.payload
            })
        case actions.SET_PROGRAMME_PRICE:
            programme = { ...state.calculatedProgramme };
            programme.price = action.payload
            newstate = { ...state.calculatedProgramme, ...programme }

            return Object.assign({}, state, {
                calculatedProgramme: newstate
            })
        case actions.SET_PROGRAMME_TYPE:
            programme = { ...state.calculatedProgramme };
            programme.type = action.payload;
            newstate = { ...state.calculatedProgramme, ...programme }

            return Object.assign({}, state, {
                calculatedProgramme: newstate
            })
        case actions.INCREMENT_DEPENDENTS:
            programme = { ...state.calculatedProgramme };
            programme.dependents = action ? programme.dependents + action.action : programme.dependents + 1;
            newstate = { ...state.calculatedProgramme, ...programme }

            return Object.assign({}, state, {
                calculatedProgramme: newstate
            })
        case actions.DECREMENT_DEPENDENTS:
            programme = { ...state.calculatedProgramme };
            programme.dependents = action ? programme.dependents - action.action : programme.dependents - 1;
            newstate = { ...state.calculatedProgramme, ...programme }

            return Object.assign({}, state, {
                calculatedProgramme: newstate
            })
        case actions.INCREMENT_SPOUSE:
            programme = { ...state.calculatedProgramme };
            programme.spouse = true;
            newstate = { ...state.calculatedProgramme, ...programme }

            return Object.assign({}, state, {
                calculatedProgramme: newstate
            })
        case actions.DECREMENT_SPOUSE:
            programme = { ...state.calculatedProgramme };
            programme.spouse = false;
            newstate = { ...state.calculatedProgramme, ...programme }

            return Object.assign({}, state, {
                calculatedProgramme: newstate
            })
        case actions.SET_CHOSEN_COUNTRY:
            return Object.assign({}, state, {
                chosenCountry: action.payload
            })
        case actions.RESET_PROGRAMME:
            return Object.assign({}, state, {
                calculatedProgramme: {
                    price: 0,
                    type: null,
                    dependents: 0,
                    spouse: false,
                },
                ProgrammeDetails: {}
            })
        default:
            return state;
    }
}