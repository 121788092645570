import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { MDBRow, MDBCol } from 'mdbreact';
import Input from '../../components/shared/inputs';
import RfpForm from './rfp-form';
import ContentCard from './content-card';
import Suggestions from './suggestions';
import { Markup } from 'interweave';

class rightHandSide extends Component {

    componentDidMount() {
    }

    filterDataHandler = (event = null) => {
        if (event !== null) {
            const currentFilters = [...this.props.investmentsList];
            const index = currentFilters.findIndex(filter => event.target.id === filter.type);
            currentFilters[index + 1].type = event.target.value;
        }
    }

    render() {
        const calculatorFields = this.props.chosenCountry[0].familyCalculator;
        const calc = calculatorFields.map(field => {
            const placeholder = field.placeholder ? field.placeholder : "";

            const data = field.data ? { ...field.data } : null;
            let values = null, initialInvestmentType = null;

            if (field.mappedOutput && field.mappedOutput === "minByInvestment") {
                values = this.props.investmentsList;
                initialInvestmentType = this.props.currentInvestmentType;
            } else {
                values = field.data ? field.data.values : null;
            }

            return (
                <MDBCol col="md-4" key={field.id} >
                    <Input id={field.id} label={field.label} inputType={field.type}
                        placeholder={placeholder} inputWidth={field.inputWidth} labelWidth={field.labelWidth} incremental={field.incremental} test={data} list={values} selectedVal={initialInvestmentType ? initialInvestmentType : null} change={(event) => this.props.change(event)} />
                </MDBCol >
            )
        });

        const disclaimerArea = () => {
            if (this.props.disclaimer != "") {
                return (
                    <MDBCol col="md-12" className="mt-5">
                        <hr className="w-100" />
                        <div className="mr-auto grey-text w-100 cbi-cost-disclaimer ">
                            <Markup content={this.props.disclaimer}> </Markup>
                        </div>
                    </MDBCol>
                )
            }
        }

        return (
            <Fragment>
                <MDBRow className="mt-4">
                    <ContentCard title="Cost Calculator" info="more-info" marginClass="mr-auto">
                        {calc}
                        {disclaimerArea()}
                    </ContentCard>
                </MDBRow>

                <MDBRow className="mt-4">
                    <ContentCard title="Request Private Consultation" marginClass="mr-auto">
                        <RfpForm />
                    </ContentCard>
                </MDBRow>

                <MDBRow className="mt-4">
                    <ContentCard title="Other Suggested Countries" marginClass="mr-auto">
                        <Suggestions />
                    </ContentCard>
                </MDBRow>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        chosenCountry: state.countries.chosenCountry,
        investmentsList: state.countries.chosenCountry[0].minByInvestment,
        permutations: state.countries.countryPermutations,
        currentInvestmentType: state.countries.calculatedProgramme.type
    }
}

export default connect(mapStateToProps)(rightHandSide)