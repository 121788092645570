import React, { Component } from 'react';

class CustomSelect extends Component {
    state = {
        selectedValue: null
    };

    componentDidMount() {
        this.setState({
            selectedValue: this.props.value
        })
    }

    selectedValueHandler = (event) => {
        this.setState({
            selectedValue: event.target.value
        });
        this.props.onChange(event);
    }

    render() {
        return (
            <select id={this.props.id} onChange={(event) => this.selectedValueHandler(event)} value={this.state.selectedValue}
                incremental={this.props.incremental} className="browser-default custom-select" >
                <option className="grey-text" value="Any">Any</option>
                {this.props.data}
            </select >
        );
    }
}

export default CustomSelect