import * as actions from '../actions/actions';

const data = { inputFields: [] }

export const costCalcFormReducer = (state = data, action) => {
    switch (action.type) {
        case actions.GET_COST_CALC:
            return Object.assign({}, state, {
                inputFields: action.payload
            })
        default:
            return state;
    }
}