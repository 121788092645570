import * as actions from '../actions/actions';

const data = {
    filters: [],
    currentFilters: [],
    filteredCountries: null,
    suggestedCountries: null,
    suggestedLimiter: 2,
    modal: {
        type: "success",
        title: "",
        subtitle: "",
        buttonText: "",
        show: false
    },
    loader: {
        show: false
    }
};

export const settingsReducer = (state = data, action) => {
    let data = null;
    let newstate = null;

    switch (action.type) {
        case actions.GET_FILTERS:
            return Object.assign({}, state, {
                filters: action.payload
            })
        case actions.SET_CURRENT_FILTERS:
            return Object.assign({}, state, {
                currentFilters: action.payload
            })
        case actions.SET_FILTERED_COUNTRIES:
            return Object.assign({}, state, {
                filteredCountries: action.payload
            })
        case actions.SET_SUGGESTED_COUNTRIES:
            return Object.assign({}, state, {
                suggestedCountries: action.payload
            })
        case actions.SET_MODAL:
            return Object.assign({}, state, {
                modal: action.payload
            })
        case actions.HIDE_MODAL:
            data = { ...action.payload };
            data.show = false;
            newstate = { ...state.modal, ...data };

            return Object.assign({}, state, {
                modal: newstate
            })
        case actions.SHOW_MODAL:
            data = { ...action.payload };
            data.show = true;
            newstate = { ...state.modal, ...data };

            return Object.assign({}, state, {
                modal: newstate
            })
        case actions.HIDE_LOADER:
            data = { ...action.payload };
            data.show = false;
            newstate = { ...state.modal, ...data };

            return Object.assign({}, state, {
                loader: newstate
            })
        case actions.SHOW_LOADER:
            data = { ...action.payload };
            data.show = true;
            newstate = { ...state.modal, ...data };

            return Object.assign({}, state, {
                loader: newstate
            })
        default:
            return state;
    }
}