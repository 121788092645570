import axios from 'axios';

export const cbiGet = axios.create({
    baseURL: 'https://cbifunctionapp.azurewebsites.net/api/GetCollection?code=pBoLlTtBpFeU4sjz8kZrFxiX6H7R4azwJMyhRn2NPAysP/jJOcAaQQ==&collection='
    //baseURL: 'https://cbifunctionapp-dev.azurewebsites.net/api/GetCollection?code=fYsvvEHd9AhfrTecdXOuoOmmelqTqKmo5NZbiYIRobjUvXIa1yFv9g==&collection='
});

export const cbiPost = axios.create({
    baseURL: 'https://cbifunctionapp.azurewebsites.net/api/RfpPost?code=pBoLlTtBpFeU4sjz8kZrFxiX6H7R4azwJMyhRn2NPAysP/jJOcAaQQ=='
    //baseURL: 'https://cbifunctionapp-dev.azurewebsites.net/api/RfpPost?code=strLmSUxd2YJKxL/7jPlh0Ko1UXJLygKMaksHjNNDTaucfz0hfALgg=='
});