import React, { Fragment } from 'react';
import Home from '../src/pages/home';
import Profile from '../src/pages/profile/index';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/scss/mdb.scss';
import './styling/main.scss'

function MyApp() {
  return (
    <Fragment>
      <div id="cbi-modal"></div>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/home" exact component={Home} />
          <Route path="/profile/:id" exact component={Profile} />
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
}

export default MyApp;