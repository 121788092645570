import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getFilters } from '../store/actions/settingsActions';
import { getCountries } from '../store/actions/countryActions';
import { setCurrentFilters, setFilteredCountries } from '../store/actions/settingsActions';

import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import CustomHeader from '../components/shared/header';
import CustomFooter from '../components/shared/footer';
import Loader from '../components/shared/loader';
import Title from '../components/shared/page-title';
import Input from '../components/shared/inputs';
import ProgrammeList from '../components/home/programme-list';

class MyApp extends Component {

  state = {
    filters: []
  }

  componentDidMount() {
    this.dataLoaderHandler();
  }

  async dataLoaderHandler() {
    const { dispatch } = this.props;
    await dispatch(getFilters());
    await dispatch(getCountries());


    this.setState({
      filters: [...this.props.filters]
    })

    this.filterDataHandler();
  }

  filterDataHandler = (event = null) => {
    if (event !== null) {
      const currentFilters = [...this.state.filters];
      const index = currentFilters.findIndex(filter => event.target.id === filter.id);
      currentFilters[index].data.value = event.target.value;
      this.setState({ filters: currentFilters });
    }
    this.updateActualDataHandler();
  }

  fetchPropertiesHandler = (obj, array, search) => {
    Object.keys(obj).forEach(key => {
      if (key == search)
        array.push(obj[key]);

      if (typeof obj[key] === 'object')
        this.fetchPropertiesHandler(obj[key], array, search)
    })
  }

  filter = (mappedOutput, search, currentValue) => {
    let values = [];
    this.fetchPropertiesHandler(mappedOutput, values, search);
    let within = values.filter(value => value <= currentValue);
    return (within.length > 0) ? true : false;
  }

  updateActualDataHandler = () => {
    const currentFilters = [...this.state.filters];
    let data = [...this.props.countries];
    let final = [];

    currentFilters.map(filter => {
      let mappedOutput = filter.mappedOutput;
      let search = filter.filteredInput;
      let currentValue = filter.data.value;

      switch (filter.type) {
        case "slider":
          final = data.filter(country => {
            if (typeof country[mappedOutput] == "object") {
              return this.filter(country[mappedOutput], search, currentValue);
            }
            else {
              return (country[mappedOutput] <= currentValue) ? true : false;
            }
          })
          break;
        case "select":
          if (currentValue !== "" && currentValue !== "Any") {
            final = data.filter(country => {
              if (typeof country[mappedOutput] == "object") {
                let values = [];
                this.fetchPropertiesHandler(country[mappedOutput], values, search);
                let within = values.filter(value => value == currentValue);
                return (within.length > 0) ? true : false;
              }
            });
          }
          break;
        default:
          break;
      }

      data = [...final];
    });

    this.props.dispatch(setFilteredCountries([...data]));
    this.props.dispatch(setCurrentFilters({ ...currentFilters }));
  }

  render() {
    if (!this.props.filteredCountries) {
      return <Loader />;
    }

    const filters = this.state.filters.map((filter, index) => {
      return (
        <MDBCol sm="12" md="6" lg="4" className="py-4" key={filter.id}>
          <Input id={filter.id} label={filter.label} inputType={filter.type} placeholder={filter.placeholder} test={{ ...filter.data }}
            list={filter.data.values} change={this.filterDataHandler} />
        </MDBCol>
      )
    });

    return (
      <div className="App">
        <Fragment>
          <CustomHeader />

          <MDBContainer fluid>
            <MDBRow className="mt-4">
              <MDBCol lg="10" md="12" className="offset-lg-1">
                <Title title="Citizenship By Investment" />
              </MDBCol>
            </MDBRow>

            <MDBRow className="mt-4">
              <MDBCol lg="10" md="12" className="offset-lg-1">
                <MDBRow className="m-0 p-4 bg-white z-depth-1">
                  {filters}
                </MDBRow>
              </MDBCol>
            </MDBRow>

            <MDBRow className="mt-5 mb-4">
              <ProgrammeList list={this.props.filteredCountries} click={this.programmeSelectedhandler} />
            </MDBRow>
          </MDBContainer>

          <CustomFooter />
        </Fragment>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countries: state.countries.countries,
    filters: state.settings.filters,
    currentFilters: state.settings.currentFilters,
    filteredCountries: state.settings.filteredCountries
  }
}

export default connect(mapStateToProps)(MyApp);