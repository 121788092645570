import { cbiGet } from '../../connections/cbi';
import * as actions from './actions';

export const getCountries = () => {
    return async dispatch => {
        const response = await cbiGet.get('Countries');
        let countryList = response.data.map((country) => {
            return country;
        })

        countryList.sort((first, second) => {
            return (first.countryName < second.countryName) ? -1 : (first.countryName > second.countryName) ? 1 : 0;
        })

        dispatch({
            type: actions.GET_COUNTRIES,
            payload: countryList
        })
    }
}

export const getCountry = (id) => {
    return async dispatch => {
        const response = await cbiGet.get('Countries');
        const country = response.data.filter(country => country.id == id);
        const prices = country[0].minByInvestment.map(price => price);

        const lowestInvestmentIndex = (a) => {
            let lowest = 0;

            for (let i = 0; i < a.length; i++) {
                if (a[i].price < a[lowest].price) {
                    lowest = i;
                }
            }

            return lowest;
        }

        const index = lowestInvestmentIndex(prices);
        const investmentPackage = prices[index];

        dispatch({
            type: actions.GET_COUNTRY,
            payload: { country, investmentPackage }
        })
    }
}

export const getPermutations = (id) => {
    return async dispatch => {
        const response = await cbiGet.get('Permutations');
        const permutations = response.data.filter(permutation => permutation.countryId == id);
        dispatch({
            type: actions.GET_PERMUTATIONS,
            payload: permutations[0].permutations
        })
    }
}

export const setIncrementalProgrammePrice = (price, increment = false) => {
    return (dispatch, getState) => {
        const currentState = getState();
        let payload = 0;

        if (price == null || price == undefined) {
            payload = currentState.countries.chosenInvestmentPackage.price;
        }
        else {
            payload = increment ? currentState.countries.calculatedProgramme.price + price : currentState.countries.calculatedProgramme.price - price;
        }
        dispatch({
            type: actions.SET_PROGRAMME_PRICE,
            payload: payload
        })
    }
}

export const setProgrammeDetails = (name, value) => {

    let data = {};
    data[name] = value;

    return {
        type: actions.PROGRAMME_DETAILS,
        payload: data
    }
}

export const resetProgrammeDetails = () => {
    return {
        type: actions.RESET_PROGRAMME
    }
}

export const setProgrammePrice = () => {
    return (dispatch, getState) => {
        let currentState = getState();
        let price = 0;
        let spouse = false;
        let dependents = 0;
        let filteredPermutations = null;
        let chosenPermutation = null;
        let minimumDependents = 0;

        if (currentState.countries.calculatedProgramme.type === "Any" || currentState.countries.calculatedProgramme.type === "") {
            let priceList = currentState.countries.chosenCountry[0].minByInvestment.map(p => p.price);
            price = Math.min(...priceList);
        }
        else {

            switch (true) {
                case (!currentState.countries.calculatedProgramme.spouse && currentState.countries.calculatedProgramme.dependents > 0):
                    spouse = false;
                    dependents = currentState.countries.calculatedProgramme.dependents;
                    break;
                case (currentState.countries.calculatedProgramme.spouse && currentState.countries.calculatedProgramme.dependents > 0):
                    spouse = false;
                    dependents = currentState.countries.calculatedProgramme.dependents + 1;
                    break;
                case (currentState.countries.calculatedProgramme.spouse && currentState.countries.calculatedProgramme.dependents <= 0):
                    spouse = true;
                    dependents = 0;
                    break;
                default:
                    break;
            }

            if (currentState.countries.countryPermutations != "") {
                //check permutations per investment type
                filteredPermutations = currentState.countries.countryPermutations.filter(permutation => {
                    return permutation.investmentType === currentState.countries.calculatedProgramme.type ? true : false;
                })

                if (filteredPermutations.length <= 0) {
                    price = 0;
                }
                else if (filteredPermutations.length == 1) {
                    price = filteredPermutations[0].price + (dependents * filteredPermutations[0].incremental)
                }
                else {
                    chosenPermutation = filteredPermutations.filter(permutation => {
                        if (currentState.countries.calculatedProgramme.type === permutation.investmentType) {
                            //check if there are any dependents
                            switch (true) {
                                case (permutation.spouse && spouse):
                                    return true;
                                    break;
                                case (!permutation.spouse && !spouse):
                                    if (permutation.dependents[0] !== undefined && permutation.dependents[0] !== undefined) {
                                        minimumDependents = permutation.dependents[0].min;
                                        return (dependents >= permutation.dependents[0].min && dependents <= permutation.dependents[0].max) ? true : false;
                                    }
                                    else if (permutation.dependents == dependents) {
                                        return true;
                                    }
                                    break;
                                default:
                                    return false;
                            }
                        }
                    })

                    price = chosenPermutation[0] ? chosenPermutation[0].price + ((dependents - minimumDependents) * chosenPermutation[0].incremental) : 0;

                }
            }
            else {
                price = 0;
            }
        }
        dispatch({
            type: actions.SET_PROGRAMME_PRICE,
            payload: price
        })
    }
}

export const setProgrammeType = (type) => {
    return {
        type: actions.SET_PROGRAMME_TYPE,
        payload: type
    }
}

export const incrementDependents = (number) => {
    return {
        type: actions.INCREMENT_DEPENDENTS,
        action: number
    }
}

export const decrementDependents = (number) => {
    return {
        type: actions.DECREMENT_DEPENDENTS,
        action: number
    }
}

export const incrementSpouse = () => {
    return {
        type: actions.INCREMENT_SPOUSE
    }
}

export const decrementSpouse = () => {
    return {
        type: actions.DECREMENT_SPOUSE
    }
}

export const setChosenCountry = (country) => {
    return {
        type: actions.SET_CHOSEN_COUNTRY,
        payload: country
    }
}