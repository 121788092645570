import React from 'react'
import { MDBIcon } from 'mdbreact';

const pageTitle = (props) => {
    return (
        <div className="p-4 my-4 bg-white cbi-page-title z-depth-1">
            <div className="cbi-page-title-holder">
                <MDBIcon icon="passport" size="4x" className="cbi-page-title-icon" />
                <h1 className=" p-l-2 h1-responsive mb-0 cbi-page-title-heading">{props.title}</h1>
            </div>
        </div>
    )
}

export default pageTitle;