import React, { Fragment } from 'react';
import { MDBRow, MDBInput, MDBCol } from 'mdbreact';
import CustomNumber from './customNumber';
import CustomRange from './customRange';
import CustomSelect from './customSelect';

const inputs = (props) => {
    let input = null;
    const labelWidth = props.labelWidth ? props.labelWidth : "12";
    const inputWidth = props.inputWidth ? props.inputWidth : "12";
    const incremental = props.incremental ? props.incremental : "";
    const restriction = props.restriction ? props.restriction : "text";
    const placeholder = props.placeholder ? props.placeholder : "";
    const label = props.label ? props.label : null;
    const id = props.id ? props.id : "";
    let data = '';
    let value = '', min = '', max = '', currency = null;
    if (props.test && props.test !== null) {
        value = props.test.value ? props.test.value : 1;
        currency = props.test.currency ? props.test.currency : null;
        min = props.test.min ? props.test.min : 0;
        max = props.test.max ? props.test.max : 100;
    }
    let selectedVal = props.selectedVal ? props.selectedVal : "";

    const labels = label ?
        <MDBCol sm="12" md={labelWidth} lg={labelWidth} xl={labelWidth}>
            <label htmlFor={id}>{label}</label>
        </MDBCol> : null;

    const inputContainer = (input) => {
        return (
            <MDBRow className="form-group">
                {labels}
                <MDBCol sm="12" md={inputWidth} lg={inputWidth} xl={inputWidth}>
                    {input}
                </MDBCol>
            </MDBRow>
        );
    }

    switch (props.inputType) {
        case 'field-input':
            input = <MDBInput id={id} type={restriction} label={placeholder} outline />
            break;
        case 'basic-input':
            input = inputContainer(<input id={id} type="text" className="form-control" />);
            break;
        case 'slider':
            input = inputContainer(
                <CustomRange id={id} min={min} max={max} value={value} onChange={(event) => props.change(event)} currency={currency}></CustomRange>
            );
            break;
        case 'checkbox':
            input = <MDBInput label={label} filled type="checkbox" id={id} />;
            break;
        case 'radio':
            data = (props.list) ?
                props.list.map((item, index) => {
                    return (
                        <label className="cbi-radio" key={index}>
                            <p>{item.radioLabel}</p>
                            <input name={label} type="radio" value={item.radioLabel} incremental={incremental} onChange={(event) => props.change(event)} id={id} />
                            <span className="radiobtn"></span>
                        </label>
                    )
                }) : null;
            input = inputContainer(data);
            break;
        case 'select':
            data = props.list ? props.list.map((item, index) => <option key={index} value={item.type}>{item.type}</option>) : null;
            input = inputContainer(
                <CustomSelect id={id} onChange={(event) => props.change(event)} value={selectedVal} incremental={incremental} data={data} />
            );
            break;
        case 'number':
            input = inputContainer(<CustomNumber id={id} type="number" max={max} min={min} placeholder={min} incremental={incremental} data-prev-value="0" change={props.change} />);
            break;
        default:
            break;
    }

    return (
        <Fragment>{input}</Fragment>
    )
}

export default inputs;