import React, { Fragment } from 'react';
import { MDBCard, MDBCardBody, MDBCol, MDBRow, MDBIcon, MDBTooltip, MDBBtn } from 'mdbreact';

const familyForm = (props) => {
    const size = props.size ? props.size : "10";
    const marginClass = props.marginClass ? props.marginClass : "mx-auto";
    const bodyClass = props.bodyClass ? props.bodyClass : "mx-4";

    const moreInfo = props.info && props.info === "more-info" ?
        <MDBTooltip placement="top">
            <MDBBtn tag="a" className="cbi-tooltip-icon pl-2">
                <MDBIcon icon="info-circle" className="grey-text" />
            </MDBBtn>
            <div>Please read the disclaimer below</div>
        </MDBTooltip> : null

    const title = props.title ?
        <div className="header p-3 border-bottom border-light">
            <MDBRow className="d-flex mx-3 my-1">
                <h5 className="grey-text m-0">
                    {props.title}
                </h5>
                {moreInfo}
            </MDBRow>
        </div> : null;

    return (
        <Fragment>
            <MDBCol sm={12} md={12} lg={size} xl={size} className={marginClass}>
                <MDBCard>
                    {title}
                    <MDBCardBody className={bodyClass}>
                        {props.children}
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </Fragment>
    )
}

export default familyForm;