import React from 'react';
import { MDBContainer, MDBFooter } from 'mdbreact';

const footer = () => {
    return (
        <MDBFooter className="z-depth-1 mt-auto">
            <div className="footer-copyright text-center py-5 px-5">
                <MDBContainer fluid>
                    <p>All Chetcuti Cauchi firms operate separately and independetely under the Chetcuti Cauchi mark.</p>
                    <small>
                        &copy; {new Date().getFullYear()}  Chetcuti Cauchi, Malta. All rights reserved.
                        <a href="https://www.chetcuticauchi.com/terms-of-use" rel="noopener noreferrer" target="_blank">Terms of Use</a>
                        <a href="https://www.chetcuticauchi.com/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
                        <a href="https://www.chetcuticauchi.com/cookie-policy" rel="noopener noreferrer" target="_blank">Cookie Policy</a>
                    </small>
                </MDBContainer>
            </div>
        </MDBFooter>
    );
}

export default footer;