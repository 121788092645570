import React, { Component } from 'react'
import { connect } from 'react-redux';
import ProgrammeCard from '../shared/programme-card';
import { MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import { getCountries } from '../../store/actions/countryActions';
import { withRouter } from 'react-router-dom'

class Suggestions extends Component {
    async countriesHandler() {
        const { dispatch } = this.props;
        await dispatch(getCountries());
    }

    componentDidMount() {
        this.countriesHandler();
    }

    nagivate = (id) => {
        let { history } = this.props;
        history.push({
            pathname: `/profile/${id}`
        });
    }

    cardRender = (country) => {
        const countryImage = `/images/countries/${country.countryName}/image/image.jpg`;
        return (
            <MDBCol sm="12" md="6" lg="6" className="mb-4" key={country.id}>
                <ProgrammeCard id={country.id} image={countryImage} name={country.countryName} click={this.nagivate} />
            </MDBCol>
        )
    }

    render() {
        let programmes = null;
        if (this.props.filteredCountries && this.props.filteredCountries.length > 0) {
            programmes = this.props.filteredCountries.map(country => {
                return this.cardRender(country);
            })
        }
        else {
            programmes =
                <div className="cbi-vertical-alert">
                    <MDBIcon icon="times" size="8x" />
                    <h2>No suggestions available</h2>
                </div>
        }

        return (
            <div>
                <MDBRow className="m-0 p-0">
                    {programmes}
                </MDBRow>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filteredCountries: state.settings.suggestedCountries,
        limiter: state.settings.suggestedLimiter,
        chosenCountry: state.countries.chosenCountry,
        countries: state.countries.countries
    }
}

export default connect(mapStateToProps)(withRouter(Suggestions));