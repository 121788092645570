import { cbiGet } from '../../connections/cbi';
import * as actions from './actions';

export const getFilters = () => {
    return async dispatch => {
        const investmentsListResponse = await cbiGet.get('InvestmentTypes');
        const filtersResponse = await cbiGet.get('Settings');

        const filtersList = filtersResponse.data.map(filter => {
            if (filter.load) {
                const filteredList = investmentsListResponse.data.filter(investmentType =>
                    investmentType.listName === filter.load);
                filter.data.values = filteredList[0].data;
            }

            return filter;
        });

        dispatch({
            type: actions.GET_FILTERS,
            payload: filtersList
        })
    }
}

export const setCurrentFilters = (filters) => {
    return ({
        type: actions.SET_CURRENT_FILTERS,
        payload: filters
    })
}

export const setFilteredCountries = (countries) => {
    return {
        type: actions.SET_FILTERED_COUNTRIES,
        payload: countries
    }
}

/*export const setSuggestedCountries = () => {

    return async (dispatch, getState) => {
        const currentState = getState();
        const currentPrice = currentState.countries.calculatedProgramme.price;
        const closestRanges = [];
        const currentId = currentState.countries.chosenCountry[0].id;

        const response = await cbiGet.get('Countries');

        debugger;
        response.data.map(country => {
            if (currentId !== country.id) {
                let closest = country.minByInvestment.reduce((prev, curr) => {
                    return (Math.abs(curr.price - currentPrice) < Math.abs(prev.price - currentPrice) ? curr : prev);
                });

                closestRanges.push({
                    country,
                    closest
                })
            }
        })

        closestRanges.sort((a, b) => {
            return Math.abs(currentPrice - a.closest.price) - Math.abs(currentPrice - b.closest.price)
        });

        dispatch({
            type: actions.SET_SUGGESTED_COUNTRIES,
            payload: [
                closestRanges[0].country,
                closestRanges[1].country
            ]
        })
    }
}*/

export const setSuggestedCountries = () => {

    return async (dispatch, getState) => {
        const currentState = getState();

        const response = await cbiGet.get('Countries');

        debugger;
        let suggestedCountries = [];
        currentState.countries.chosenCountry[0].suggestedCountries.map(suggested => {
            response.data.map(country => {return suggested.name.toLowerCase() == country.countryName.toLowerCase() ? suggestedCountries.push(country) : null})
        })


        dispatch({
            type: actions.SET_SUGGESTED_COUNTRIES,
            payload: suggestedCountries
        })
    }
}



export const setModal = (modal) => {
    return {
        type: actions.SET_MODAL,
        payload: modal
    }
}

export const hideModal = () => {
    return {
        type: actions.HIDE_MODAL
    }
}

export const showModal = () => {
    return {
        type: actions.SHOW_MODAL
    }
}