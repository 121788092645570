import React from 'react';
import ReactDOM from 'react-dom';

class loader extends React.Component {

    componentDidMount() {
        this.element = document.querySelector("#cbi-loader");
    }

    render() {
        if (this.element === undefined) {
            return null
        }

        return ReactDOM.createPortal(
            <div className="fullscreen-loader d-flex justify-content-center align-items-center flex-row-reverse">
                <div className="spinner-border" role="status">
                </div>
            </div>, this.element
        )
    }
}

export default loader;