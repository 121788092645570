export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_FILTERS = "GET_FILTERS";
export const GET_INVESTMENT_TYPES = "GET_INVESTMENT_TYPES";
export const GET_COST_CALC = "GET_COST_CALC";
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_PERMUTATIONS = "GET_PERMUTATIONS";

export const SET_FILTERED_COUNTRIES = "SET_FILTERED_COUNTRIES";
export const SET_SUGGESTED_COUNTRIES = "SET_SUGGESTED_COUNTRIES";
export const SET_CHOSEN_COUNTRY = "SET_CHOSEN_COUNTRY";

export const SET_PROFILE = "SET_PROFILE";
export const SET_PROFILE_PROP = "SET_PROFILE_PROP";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

export const SET_MODAL = "SET_MODAL";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const PROGRAMME_DETAILS = "PROGRAMME_DETAILS";

export const HIDE_LOADER = "HIDE_LOADER";
export const SHOW_LOADER = "SHOW_LOADER";

export const SET_FILTERS = "SET_FILTERS";
export const SET_CURRENT_FILTERS = "SET_CURRENT_FILTERS";

export const SET_PROGRAMME_PRICE = "SET_PROGRAMME_PRICE";
export const SET_PROGRAMME_TYPE = "SET_PROGRAMME_TYPE";
export const RESET_PROGRAMME = "RESET_PORGRAMME";

export const INCREMENT_DEPENDENTS = "INCREMENT_DEPENDENTS";
export const DECREMENT_DEPENDENTS = "DECREMENT_DEPENDENTS";

export const INCREMENT_SPOUSE = "INCREMENT_SPOUSE";
export const DECREMENT_SPOUSE = "DECREMENT_SPOUSE";