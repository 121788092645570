import React, { Fragment } from 'react';
import { MDBRow } from 'mdbreact';
import Price from './calculated-price';
import CountryDesc from './country-description';

const leftHandSide = (props) => {
    return (
        <Fragment>
            <MDBRow className="mt-4 d-none d-sm-none d-lg-block">
                <Price price={props.price} />
            </MDBRow>

            <MDBRow className="mt-4">
                <CountryDesc countryName={props.name} countryImage={props.image} countryDesc={props.description} />
            </MDBRow>
        </Fragment>
    )
}

export default leftHandSide;