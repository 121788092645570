import React from 'react';
import ReactDOM from 'react-dom';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBIcon } from 'mdbreact';
import { connect } from 'react-redux';
import { hideModal } from '../../store/actions/settingsActions';

class Modal extends React.Component {
    state = {
        modal: true
    }

    toggle = () => {
        const { dispatch } = this.props;
        dispatch(hideModal());
    }

    setIcon = (type) => {
        switch (type) {
            case "success":
                return <MDBIcon icon="check-circle" size="10x" className="green-text" />;
            case "danger":
                return <MDBIcon icon="times-circle" size="10x" className="red-text" />;
            case "warning":
                return <MDBIcon icon="exclamation-circle" size="10x" className="orange-text" />;
            default:
                return "";
        }
    }

    setToggle = () => {
        if (this.props.toggle) {
            return () => this.props.toggle();
        }
        else {
            return () => this.toggle();
        }
    }

    setButton = () => {
        if (this.props.button) {
            return <MDBBtn color={this.props.modal.type} onClick={this.setToggle()}>{this.props.modal.buttonText}</MDBBtn>
        }
    }

    render() {
        return ReactDOM.createPortal(
            <MDBContainer>
                <MDBModal isOpen={this.props.modal.show} toggle={this.toggle} centered>
                    <MDBModalBody className="m-auto py-5 text-center">
                        {this.setIcon(this.props.modal.type)}
                        <div className="w-100 py-3">
                            <h1>{this.props.modal.title}</h1>
                            <p>{this.props.modal.subtitle}</p>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        {this.setButton()}
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>,
            document.querySelector('#cbi-modal')
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.settings.modal
    }
}


export default connect(mapStateToProps)(Modal);