import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { MDBRow, MDBCol, MDBContainer, MDBIcon } from 'mdbreact';
import CustomHeader from '../../components/shared/header';
import CustomFooter from '../../components/shared/footer';
import Loader from '../../components/shared/loader';
import Lhs from '../../components/country-profile/lhs';
import Rhs from '../../components/country-profile/rhs';
import { getCostCalc } from '../../store/actions/costCalcFormAction';
import { getCountry, setProgrammeType } from '../../store/actions/countryActions';
import ContentCard from '../../components/country-profile/content-card';
import { setSuggestedCountries } from '../../store/actions/settingsActions';
import { resetProgrammeDetails, incrementDependents, decrementDependents, getPermutations, setIncrementalProgrammePrice, setProgrammePrice, incrementSpouse, decrementSpouse, setProgrammeDetails } from '../../store/actions/countryActions';
import Price from '../../components/country-profile/calculated-price';
class ProgrammeProfilePage extends Component {

    state = {
        showModal: true
    }

    loadData = async () => {
        this.setState({ showModal: true });
        await this.dataHandler();
        this.setState({ showModal: false });
    }

    componentDidMount() {
        this.loadData();
        const { dispatch } = this.props;
        dispatch(resetProgrammeDetails());
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.loadData();
            const { dispatch } = this.props;
            dispatch(resetProgrammeDetails());
        }
    }

    async dataHandler() {
        const { dispatch } = this.props;
        await dispatch(getCountry(this.props.match.params.id));

        let filters = { ...this.props.chosenFilters };
        let selectedType = "Any";

        Object.keys(filters).forEach((filter) => {
            if (filters[filter].id === "test3") {
                selectedType = filters[filter].data.value
            }
        });

        if (!this.props.chosenCountry[0].isIncremental)
            await dispatch(getPermutations(this.props.match.params.id));
        await dispatch(setProgrammeType(selectedType));
        await dispatch(getCostCalc());
        if (this.props.chosenCountry[0].isIncremental) {
            await dispatch(setIncrementalProgrammePrice());
        }
        else {
            await dispatch(setProgrammePrice());
        }

        await dispatch(setSuggestedCountries());
    }

    permutationHandler = async (event) => {
        const { dispatch } = this.props;
        switch (true) {
            case event.target.type == "number":
                if (event.target.attributes.incremental.value == "dependent") {
                    let currentNum = isNaN(event.target.valueAsNumber) ? 0 : event.target.valueAsNumber;
                    let previousNum = isNaN(parseInt(event.target.previousValue)) ? 0 : parseInt(event.target.previousValue);

                    (previousNum < currentNum) ?
                        dispatch(incrementDependents(currentNum - previousNum)) :
                        dispatch(decrementDependents(previousNum - currentNum));

                    dispatch(setProgrammeDetails(event.target.labels[0].innerText, event.target.value));
                }
                break;
            case event.target.type == "radio":
                if (event.target.attributes.incremental.value == "spouse")
                    event.target.value === "Yes" ? dispatch(incrementSpouse()) : dispatch(decrementSpouse());
                dispatch(setProgrammeDetails(event.target.name, event.target.value));
                break;
            case event.target.type == "select-one":
                if (event.target.attributes.incremental.value == "investment")
                    dispatch(setProgrammeType(event.target.value));
                dispatch(setProgrammeDetails("Investment", event.target.value));
                break;
            default:
                break;
        }

        dispatch(setProgrammePrice());
    }

    incrementalHandler = async (event) => {
        const { dispatch } = this.props;
        const id = event.target.attributes.id.value;
        let incremental = null;
        let increment = false;
        let value = 0;

        switch (true) {
            case event.target.type == "number":
                incremental = this.props.chosenCountry[0].familyCalculator.filter(filter => filter.id == id)
                increment = !isNaN(event.target.valueAsNumber) ? event.target.previousValue < event.target.valueAsNumber ? true : false : false;
                value = !isNaN(event.target.valueAsNumber) ? event.target.previousValue < event.target.valueAsNumber ?
                    (incremental[0].incremental * (event.target.valueAsNumber - event.target.previousValue)) :
                    (incremental[0].incremental * (event.target.previousValue - event.target.valueAsNumber)) : incremental[0].incremental * event.target.previousValue;

                dispatch(setIncrementalProgrammePrice(value, increment));
                dispatch(setProgrammeDetails(event.target.labels[0].innerText, event.target.value));
                break;
            case event.target.type == "radio":
                incremental = this.props.chosenCountry[0].familyCalculator.filter(filter => filter.id == id)
                increment = event.target.value === "Yes" ? true : false;
                dispatch(setIncrementalProgrammePrice(incremental[0].incremental, increment));
                dispatch(setProgrammeDetails(event.target.name, event.target.value));
                break;
            case event.target.type == "select-one":
                dispatch(setProgrammeType(event.target.value));
                dispatch(setProgrammeDetails("Investment", event.target.value));
                break;
            default:
                break;
        }


    }

    dependentsHandler = async (event) => {
        const { dispatch } = this.props;
        if (this.props.chosenCountry[0].isIncremental) {
            await this.incrementalHandler(event);
        }
        else {
            await this.permutationHandler(event);
        }

        dispatch(setSuggestedCountries());
    }

    render() {
        if (this.state.showModal) {
            return <Loader />;
        }
        let countryDetails = this.props.chosenCountry[0];
        let disclaimer = countryDetails.disclaimer ? countryDetails.disclaimer : "";

        return (
            <Fragment>
                <CustomHeader />
                <MDBContainer fluid>
                    <MDBRow className="mt-4 sticky-price d-lg-none">
                        <Price price={this.props.calculatedPrice} />
                    </MDBRow>
                    <MDBRow className="mt-4">
                        <MDBCol md="12" sm="12" lg="6">
                            <Lhs
                                price={this.props.calculatedPrice}
                                name={countryDetails.countryName}
                                image={countryDetails.countryImage}
                                description={countryDetails.countryDesc}
                            />
                        </MDBCol>

                        <MDBCol md="12" sm="12" lg="6">
                            <Rhs disclaimer={disclaimer} change={this.dependentsHandler} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="my-4 pb-4 grey-text cbi-disclaimer">
                        <ContentCard size={"10"}>
                            <MDBIcon icon="info-circle" className="pr-3" />
                            <small> <strong>Disclaimer:</strong> The above cost estimate is provided for information purposes only, and does not constitute legal or professional advice. The estimate is also inclusive of programme-related fees applicable to the main applicant, but excludes legal fees. While we make every effort to ensure that the information is correct and up-to-date, we advise that you get in touch with us for an accurate and personalised quote. Data provided is updated as at June 2019.</small>
                        </ContentCard>
                    </MDBRow>
                </MDBContainer>

                <CustomFooter />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        country: state.countries.chosenCountry,
        chosenCountry: state.countries.chosenCountry,
        investmentPackage: state.countries.chosenInvestmentPackage,
        chosenFilters: state.settings.currentFilters,
        calculatedPrice: state.countries.calculatedProgramme.price
    }
}

export default connect(mapStateToProps)(ProgrammeProfilePage);