import React from 'react';

const Currency = (props) => {

    let currency = props.currency ? { style: "currency", currency: props.currency } : {};
    let number = (props.children !== null || props.children !== undefined) ? parseInt(props.children) : null;
    let formatter = new Intl.NumberFormat('en-US', currency);

    let res = props.currency !== null ? formatter.format(number).replace(/\D00$/, '') : Number(props.children).toLocaleString();

    return (
        <div>{res}</div>
    )
}

export default Currency;