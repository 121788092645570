import React, { Component, Fragment } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBInput } from 'mdbreact';
import { connect } from 'react-redux';
import { setDetails, sendRFP, clearDetails, setProfileProp } from '../../store/actions/profileActions';
import { setModal, hideModal } from '../../store/actions/settingsActions';
import ReCAPTCHA from 'react-google-recaptcha';
import Modal from '../../components/shared/modal';
import { withRouter } from 'react-router-dom';

class rfpForm extends Component {
    state = {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        residence: "",
        legal: false,
        recaptchaRef: React.createRef()
    };

    submitHandler = async (value) => {
        const { dispatch } = this.props;
        
        if (value !== "") {
            await dispatch(setDetails(this.state));
            let response = await dispatch(sendRFP());

            if (response === 200) {
                dispatch(clearDetails());
                dispatch(setModal({
                    type: "success",
                    title: "Success",
                    subtitle: "Congratulations! Your form has been submitted. You are being redirected to the home page.",
                    button: false,
                    show: true
                }));

                let { history } = this.props;

                setTimeout(() => {
                    dispatch(hideModal());
                    history.push({
                        pathname: `/home`
                    });
                }, 5000);
            }
            else {
                dispatch(setModal({
                    type: "danger",
                    title: "Error",
                    subtitle: "An error occured while trying to submit your request.",
                    buttonText: "",
                    show: true
                }));
            }
        } else {
            dispatch(setModal({
                type: "danger",
                title: "Error",
                subtitle: "Error sending the RFP, kindly try again",
                buttonText: "Close",
                show: true
            }))
        }
    };

    changeHandler = event => {
        const { dispatch } = this.props;
        dispatch(setProfileProp(event));
        this.setState({
            [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value
        });
    };

    render() {
        return (
            <Fragment>
                <Modal />
                <form onSubmit={(event) => { event.preventDefault(); this.state.recaptchaRef.current.execute() }}>
                    <ReCAPTCHA
                        ref={this.state.recaptchaRef}
                        size="invisible"
                        sitekey="6Lf6QaYUAAAAALvXM-zzsRI0LQpb8K28S4BtLJ7r"
                        onChange={value => { this.submitHandler(value) }}
                    >

                        <MDBRow className="form-group m-0">
                            <MDBCol sm="6">
                                <MDBInput id="rfp-firstname" name="firstname" value={this.props.profile.firstname} type="text" label="First Name" placeholder="First Name" onChange={this.changeHandler} required outline />
                            </MDBCol>
                            <MDBCol sm="6">
                                <MDBInput id="rfp-lastname" name="lastname" value={this.props.profile.lastname} type="text" label="Last Name" placeholder="Last Name" onChange={this.changeHandler} required outline />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="form-group m-0">
                            <MDBCol sm="12">
                                <MDBInput id="rfp-email" name="email" value={this.props.profile.email} type="email" label="Email Address" placeholder="Email Address" onChange={this.changeHandler} required outline />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="form-group m-0">
                            <MDBCol sm="12">
                                <MDBInput id="rfp-phone" name="phone" value={this.props.profile.phone} type="tel" label="Telephone Number" placeholder="Telephone Number" onChange={this.changeHandler} required outline />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="form-group m-0">
                            <MDBCol sm="12">
                                <MDBInput id="rfp-country" name="residence" value={this.props.profile.residence} restriction="text" label="Country of Residence" placeholder="Country of Residence" onChange={this.changeHandler} outline />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="form-group m-0">
                            <MDBCol sm="12">
                                <MDBInput value={this.props.profile.legal} name="legal" id="rfp-legal" type="checkbox" label="Please send me legal and other updates" onChange={this.changeHandler} />
                            </MDBCol>
                        </MDBRow>

                        <MDBBtn className="float-right" type="submit">Submit</MDBBtn>
                    </ReCAPTCHA>
                </form >
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile.profile
    }
}

export default connect(mapStateToProps)(withRouter(rfpForm));