import React from 'react';
import { MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import ProgrammeCard from '../shared/programme-card';
import { withRouter } from 'react-router-dom'

class ProgrammeList extends React.Component {

    nagivate = (id) => {
        let { history } = this.props;
        history.push({
            pathname: `/profile/${id}`
        });
    }

    render() {
        if (this.props.list <= 0 || !this.props.list) {
            return (
                <MDBCol lg="10" md="12" className="offset-lg-1">
                    <MDBRow className="m-0">
                        <div className="cbi-vertical-alert">
                            <MDBIcon className="pb-4" icon="exclamation-triangle" size="10x" />
                            <h2>No results with current filtering</h2>
                        </div>
                    </MDBRow>
                </MDBCol>
            )
        }

        const cards = this.props.list.map((programme, index) => {
            const priceList = programme.minByInvestment.map(price => price.price);
            const minPrice = Math.min(...priceList);

            const countryImage = `/images/countries/${programme.countryName}/image/image.jpg`;

            return (
                <MDBCol sm="12" md="6" lg="3" className="mb-4" key={index}>
                    <ProgrammeCard id={programme.id} image={countryImage} name={programme.countryName} minInvestment={minPrice} click={this.nagivate} />
                </MDBCol>
            );
        })

        return (
            <MDBCol lg="10" md="12" className="offset-lg-1">
                <MDBRow className="p-0">
                    {cards}
                </MDBRow>
            </MDBCol>
        )
    }




}

export default withRouter(ProgrammeList);