import { cbiGet } from '../../connections/cbi';
import * as actions from './actions';

export const getCostCalc = () => {
    return async dispatch => {

        debugger;
        const investmentsListResponse = await cbiGet.get('InvestmentTypes');
        const inputsResponse = await cbiGet.get('CostCalculator');

        const inputsList = inputsResponse.data.map(field => {
            if (field.load) {
                const inputlist = investmentsListResponse.data.filter(investmentType =>
                    investmentType.listName === field.load);
                field.data.values = inputlist[0].data;
            }

            return field;
        });

        dispatch({
            type: actions.GET_COST_CALC,
            payload: inputsList
        })
    }
}