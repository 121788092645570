import React from 'react';
import { MDBMask, MDBView, MDBRow, MDBCol, Animation } from 'mdbreact';
import Currency from '../shared/currency';

const programmeCard = (props) => {
    const onclick = (props.click && props.id) ? () => props.click(props.id) : null;
    const image = props.name ?
        <img src={process.env.PUBLIC_URL + props.image} className="img-fluid" alt={props.name} /> : null;
    const title = props.name ?
        <MDBCol md="12"><h5 className="white-text w-100 text-center programme-card-title text-bold">{props.name}</h5></MDBCol> : null;
    const price = props.minInvestment ?
        <MDBCol md="12" className="programme-card-description">
            <h6 className="white-text w-100 text-center programme-card-price">Min. Investment of: </h6>
            <h4 className="white-text text-center"><Currency currency="EUR">{props.minInvestment}</Currency></h4>
        </MDBCol> : null;

    return (
        <Animation type="fadeIn" duration="2s">
            <MDBView className="programme-card z-depth-1" onClick={onclick}>
                {image}
                <MDBMask className="flex-center" overlay="blue-grey-strong">
                    <MDBRow>
                        {title}
                        {price}
                    </MDBRow>
                </MDBMask>
            </MDBView>
        </Animation >
    )
}

export default programmeCard;