import * as actions from '../actions/actions';

const data = { showFullLoader: true };

export const generalReducer = (state = data, action) => {
    switch (action.type) {
        case actions.HIDE_MODAL:
            return Object.assign({}, state, {
                showFullLoader: false
            })
        default:
            break;
    }

    return state;
}