import * as actions from './actions';
import axios from 'axios';
import Bowser from 'bowser';

export const sendRFP = () => {
    return async (dispatch, getState) => {

        const currentState = getState();
        const browser = Bowser.getParser(window.navigator.userAgent);

        const publicIp = require('public-ip');
        const ip = await publicIp.v4();

        let submissionDetails = {
            "RFPGuid": "",
            "FirstName": currentState.profile.profile.firstname,
            "LastName": currentState.profile.profile.lastname,
            "EmailAddress": currentState.profile.profile.email,
            "TelephoneNumber": currentState.profile.profile.phone,
            "Query": "This is an RFP sent from the CIP-Index.",
            "SourceWebsite": "CIPINDEX",
            "IPAddress": ip,
            "Country": currentState.profile.profile.residence,
            "BrowserAgent": navigator.userAgent,
            "BrowserVersion": `${browser.getBrowserName()}, v${browser.getBrowserVersion()}`,
            "PostbackUrl": "https://cip-index.org/profile/" + currentState.countries.chosenCountry[0].id,
            "UserNavigation": "",
            "CreatedWhen": new Date(),
            "UserConsent": currentState.profile.profile.legal,
            "Details": {
                "Price": currentState.countries.calculatedProgramme.price.toString(),
                "Timeline": currentState.settings.currentFilters[1] ? currentState.settings.currentFilters[1].data.value.toString() : "",
                "Country": currentState.countries.chosenCountry[0].countryName,
                ...currentState.countries.ProgrammeDetails
            },
            "IsProcessed": false,
            "SalesCycleCode": currentState.countries.chosenCountry[0].salesCycleCode
        };

        const response = await axios({
            method: 'post',
            url: 'https://cbifunctionapp.azurewebsites.net/api/RfpPost?code=pBoLlTtBpFeU4sjz8kZrFxiX6H7R4azwJMyhRn2NPAysP/jJOcAaQQ==',
            //url: 'https://cbifunctionapp.azurewebsites.net/api/RfpTestPost?code=pBoLlTtBpFeU4sjz8kZrFxiX6H7R4azwJMyhRn2NPAysP/jJOcAaQQ==',
            data: submissionDetails
        });

        return response.status;
    }
}

export const setDetails = (form) => {
    return {
        type: actions.SET_PROFILE,
        payload: form
    }
}

export const clearDetails = (form) => {
    return {
        type: actions.CLEAR_PROFILE
    }
}

export const setProfileProp = (event) => {
    let object = { [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value }
    return {
        type: actions.SET_PROFILE_PROP,
        payload: object
    }
}