import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse } from 'mdbreact';

class header extends Component {
    state = {
        isOpen: false
    };

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    nagivate = () => {
        let { history } = this.props;
        history.push({
            pathname: `/`
        });
    }

    render() {
        return (
            <MDBNavbar expand="md" className="w-100 cbi-navbar px-5 py-4">
                <MDBNavbarBrand>
                    <img src={process.env.PUBLIC_URL + '/images/cc-logo.png'} alt="cc-logo" onClick={this.nagivate} />
                </MDBNavbarBrand>
                <MDBNavbarToggler onClick={this.toggleCollapse} />
                <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
                    <MDBNavbarNav right>
                        <MDBNavItem>
                            <a href="https://www.chetcuticauchi.com/office/malta" rel="noopener noreferrer" target="_blank">Contact Us</a>
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
        );
    }
}

export default withRouter(header);